.drawer {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100000;
  background-color: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(1px);

  display: none;
  justify-content: flex-end;
  .drawer__content {
    width: 600px;
    height: 100%;
    padding: 20px;
    overflow: hidden;
    background-color: var(--color-white);
  }
  .drawer__header {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    button {
      padding: 0;
      transition: color .1s linear;
      &:hover {
        color: var(--color-red);
      }
    }
  }
}

.drawer--active {
  display: flex;
}