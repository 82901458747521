.dialog {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  strong {
    font-size: 110%;
    line-height: 100%;
  }
  .dialog__btns {
    display: flex;
    gap: 10px;
  }
}