.avatar {
  height: 50px;
  width: 50px;
  border-radius: 100%;
  background-color: var(--color-gray);

  display: flex;
  align-items: center;
  justify-content: center;
  b {
    font-size: 110%;
  }
}