:root {
  --color-green: #4caf50;
  --color-red: #ef5350;
  --color-yellow: #bfa84c;
  
  --color-white: #ffffff;
  --color-black: #000000;

  --color-gray: #f1f1f1;
  --color-dark-gray: #696969;

  --color-background: #f7f4f3;
  --color-block: #ffffff;

  --color-accent: #e4755b;
  --color-second-accent: #629AEE;

  --color-translucent-15-red: rgba(239, 83, 80, 0.15);
  --color-translucent-15-yellow: rgba(216, 190, 86, 0.15);
  --color-translucent-15-green: rgba(76, 175, 79, 0.15);
  --color-translucent-15-second-accent: rgba(98, 154, 238, 0.15);
  --color-translucent-15-accent: rgba(228, 116, 91, 0.15);
}