.block {
  width: 100%;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, .05);
  background-color: var(--color-block);

  display: flex;
  flex-direction: column;
  .block__title {
    padding: 10px 0px;
    strong {
      font-size: 150%;
      line-height: 100%;
    }
  }
}