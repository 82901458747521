.input-list {
  flex: 1;
  position: relative;
  border-radius: 10px;

  .input-list__data {
    width: 100%;
    position: absolute;
    z-index: 1000;
    top: 100%;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
    background-color: var(--color-gray);
    border-radius: 0px 0px 10px 10px;

    display: none;
    flex-direction: column;
    button {
      position: relative;
      padding: 10px 12px;
      text-align: start;
      transition: background-color .1s ease-in;
      &:hover {
        color: var(--color-accent);
      }
    }
  }
  .input-list__label {
    height: 25px;
    max-width: 100px;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 7px;
    color: var(--color-green);
    background-color: #d8e7d9; // legacy color

    display: flex;
    align-items: center;
    justify-content: center;
    span {
      padding: 0px 5px !important; 
    }
  }
  .input-list__label--inactive {
    color: var(--color-dark-gray);
    background-color: var(--color-gray);
  }
  input {
    width: 100%;
    background-color: var(--color-gray);
  }
}

.input-list--open {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  input {
    border-radius: 10px 10px 0px 0px;
    background-color: var(--color-white) !important;
  }
  .input-list__data {
    display: flex;
    background-color: var(--color-white);
  }
}