.select_menu {
  width: 100%;
  button {
    width: 100%;
    padding: 10px;
    margin-bottom: 5px;
    color: var(--color-text);
    transition: background-color .1s linear;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    &:hover {
      transition: background-color .1s linear;
    }
    &:last-child {
      margin-bottom: 0 !important;
    }
    span {
      text-align: left;
    }
    img, svg {
      zoom: 0.8;
      margin-right: 10px;
    }
  }
}