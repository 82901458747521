.user-menu {
  direction: rtl;
  .user-menu__content {
    width: 350px;
    margin-bottom: 0px !important;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  .user-menu__name {
    display: flex;
    gap: 10px;
  }
  .user-menu__avatar {
    width: 75px;
    height: 75px;
    margin: 10px 0px;
  }
  .user-menu__tags {
    width: 100%;
    margin-top: 10px;

    display: flex;
    gap: 10px;
  }
  .user-menu__tag {
    padding: 10px;
    border-radius: 10px;
    
    flex: 1;
    display: flex;
    gap: 5px;
    flex-direction: column;
    span {
      font-size: 80%;
    }
    strong {
      font-size: 90%;
    }
  }
  button {
    width: 100%;
    margin-top: 10px;
    padding: 5px;
  }
}