.dropdown {
  position: relative;
  .dropdown__content { 
    max-height: 0;
    min-width: 200px;
    max-width: 500px !important;
    margin-top: 15px;
    transition: all .3s linear;
    top: 100%;
    position: absolute;
    direction: ltr;
    z-index: 99999;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    background-color: transparent;
  }
  .dropdown__content .block {
    margin-bottom: 0;
  }
}

.dropdown--hoverable:hover .dropdown__content, .dropdown--hoverable:focus ~ .dropdown__content {
  max-height: 1000px;
  transition: all .5s;
}

.dropdown--open .dropdown__content {
  max-height: 1000px;
  transition: all .5s;
}

.dropdown--rtl {
  direction: rtl !important;
}

.dropdown--center {
  left: 50%;
  transform: translate(-50%, 8px);
}

.dropdown--margin {
  margin-top: 10px;
}