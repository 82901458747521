.part-preview {
  cursor: pointer;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .part-preview__header {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .part-preview__title {
    display: flex;
    gap: 10px;
  }
  .part-preview__tags {
    margin-top: 10px;

    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: flex-start;
  }
  .part-preview__tag {
    padding: 10px;
    border-radius: 10px;

    display: flex;
    gap: 5px;
    flex-direction: column;
    span {
      font-size: 80%;
    }
    strong {
      font-size: 95%;
    }
  }
}