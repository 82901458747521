.header {
  width: 100%;
  height: 70px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  background-color: var(--color-block);
  .header__content {
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .header__nav {
    margin: 0px 40px;

    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    gap: 20px
  }
  a {
    margin-top: 3px;
    text-decoration: none;
    color: var(--color-black);
    transition: color .1s ease-in;
    &:hover {
      color: var(--color-accent);
      transition: color .1s ease-in;
    }
  }
}

