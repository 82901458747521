.order {
  gap: 10px;
  .order__tags {
    display: flex;
    gap: 10px;
  }
  .order__tag {
    padding: 10px;
    border-radius: 10px;

    display: flex;
    gap: 5px;
    flex-direction: column;
    span {
      font-size: 80%;
    }
    strong {
      font-size: 90%;
    }
  }
  table {
    margin: 0px 0px 10px 0px;
    border-collapse: collapse;
    tr {
      td {
        padding: 5px;
        border: 1px solid var(--color-dark-gray);
      }
    }
  }
}