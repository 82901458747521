.split {
  display: flex;
  gap: 20px;
  .split__block {
    overflow: hidden;
  }
  .split--grow {
    flex-grow: 1;
  }
  .split--fixed {
    min-width: 100px;
    flex-shrink: 0;
  }
}