html {
  font-family: 'Jost', sans-serif;
  background-color: var(--color-background);
}

button {
  padding: 10px 30px;
  border-radius: 10px;
  background-color: var(--color-transparent);
}

input, textarea {
  padding: 12px;
  border-radius: 10px;
}

.main {
  width: 100%;
  height: 100vh;
}

.center {
  width: 100%;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.containered {
  @extend .container;
  padding-top: 90px;
}