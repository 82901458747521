.part {
  gap: 10px;
  .part__amount {
    margin: 30px 0px;

    display: flex;
    gap: 10px;
    align-items: center;
    input {
      flex: 1;
      background-color: var(--color-gray);
    }
  }
  .part__tags {
    display: flex;
    gap: 10px;
  }
  .part__tag {
    padding: 10px;
    border-radius: 10px;

    display: flex;
    gap: 5px;
    flex-direction: column;
    span {
      font-size: 80%;
    }
    strong {
      font-size: 90%;
    }
  }
}