.login {
  width: 500px;
  height: 600px;
  padding: 20px;
  border-radius: 10px;
  background-color: var(--color-block);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  display: flex;
  flex-direction: column;
  align-items: center;
  .login__btns {
    width: 100%;

    flex-grow: 1;
    display: flex;
    flex-direction: column-reverse;
  }
  h1 {
    margin: 20px 0 20px 0;
  }
  img {
    width: 100px;
    height: 100px;
    padding: 10px;
    border-radius: 100px;
    background-color: var(--color-gray);
  }
  input {
    width: 100%;
    margin-bottom: 20px;
    background-color: var(--color-gray);
    transition: all .1s ease-in;
    &:focus {
      background-color: var(--color-white);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      transition: all .1s ease-in;
    }
  }
  button {
    width: 100%;
    color: var(--color-white);
    background-color: var(--color-accent);
  }
}