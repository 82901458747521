.menu {
  width: 100%;

  display: flex;
  flex-direction: column;
  .menu__button {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 7px;
    transition: all .1s ease-in;

    display: flex;
    align-items: center;
    &:hover {
      transition: all .1s ease-in;
    }
    &:last-child {
      margin-bottom: 0;
    }
    span {
      flex-grow: 1;
      text-align: left;
    }
    strong {
      padding: 2px 10px;
      font-size: 80%;
      border-radius: 100px;
    }
    img, svg {
      width: 24px;
      height: 24px;
      margin: 0px 10px 1px 0px;
    }
  }
  .menu__border {
    width: 100%;
    height: 1px;
    margin-bottom: 10px;
    background-color: var(--color-gray);
  }
}