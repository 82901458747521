.order_search {
  display: flex;
  flex-direction: column;
  input {
    width: 100%;
    background-color: var(--color-gray);
    transition: all .1s ease-in;
    &:focus {
      background-color: var(--color-white);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }
  }
  .order_search__filters {
    display: flex;
    gap: 20px;
  } 
  .order_search__filter {
    margin-top: 10px;

    display: flex;
    align-items: center;
    gap: 5px;
    .order_search__select-btn {
      padding: 0;
    }
  }
}