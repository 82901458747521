// default themings
.style--positive {
  background-color: var(--color-green);
  color: var(--color-white);
}

.style--negative {
  background-color: var(--color-red);
  color: var(--color-white);
}

.style--accent {
  background-color: var(--color-accent);
  color: var(--color-white);
}

.style--warning {
  background-color: var(--color-yellow);
  color: var(--color-white);
}

.style--freeze {
  background-color: var(--color-gray);
  color: var(--color-dark-gray);
}

// lite themings
.style--accent-lite {
  color: var(--color-accent);
}

.style--freeze-lite {
  color: var(--color-dark-gray);
}

.style--negative-lite {
  color: var(--color-red);
}

.style--positive-lite {
  color: var(--color-green);
}

// hover themings
.style--hover-accent:hover {
  background-color: var(--color-translucent-15-accent);
  color: var(--color-accent);
  svg {
    fill: var(--color-accent);
  }
}

.style--hover-positive:hover {
  background-color: var(--color-translucent-15-green);
  color: var(--color-green);
  svg {
    fill: var(--color-green);
  }
}

.style--hover-negative:hover {
  background-color: var(--color-translucent-15-red);
  color: var(--color-red);
  svg {
    fill: var(--color-red);
  }
}

.style--hover-white:hover {
  background-color: var(--color-white) !important;
  color: var(--color-black);
}

// fill themins
.style--fill-accent {
  fill: var(--color-accent);
}

.style--fill-positive {
  fill: var(--color-green);
}

// fill hover themings
.style--hover-fill-accent:hover {
  fill: var(--color-accent);
}

// translucent themings
.style--translucent-15-positive {
  background-color: var(--color-translucent-15-green);
  color: var(--color-green);
}

.style--translucent-15-negative {
  background-color: var(--color-translucent-15-red);
  color: var(--color-red);
}

.style--translucent-15-warning {
  background-color: var(--color-translucent-15-yellow);
  color: var(--color-yellow);
}

.style--translucent-15-accent {
  background-color: var(--color-translucent-15-accent);
  color: var(--color-accent);
}