.switch-button {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
  .switch-button__slider {
    position: absolute;
    cursor: pointer;
    border-radius: 34px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--color-gray);
    -webkit-transition: .4s;
    transition: .4s;
    &:before {
      position: absolute;
      content: "";
      height: 16px;
      width: 16px;
      left: 4px;
      bottom: 4px;
      border-radius: 50%;
      background-color: var(--color-accent);
      -webkit-transition: .4s;
      transition: .4s;
    }
  }
  input {
    opacity: 0;
    width: 0;
    height: 0;
    &:checked + .switch-button__slider {
      background-color: var(--color-gray);
    }
    &:checked + .switch-button__slider:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
    }
  }
}