.index-data {
  display: flex;
  gap: 10px;
  .index-data__tags {
    width: 100%;
    
    display: flex;
    gap: 10px;
  }
  .index-data__tag {
    padding: 10px;
    border-radius: 10px;

    display: flex;
    gap: 5px;
    flex-direction: column;
    span {
      font-size: 80%;
    }
    strong {
      font-size: 90%;
    }
  }
  .index-data__tag--flex {
    flex: 1;
  }
}

.index-warns {
  display: flex;
  flex-direction: column;
  .index-warns__header {
    margin: 10px 0px;
    font-size: 130%;
  }
}