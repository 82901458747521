.action_form {
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 15px;

  .action_form__row {
    width: 100%;
  
    display: flex; 
    justify-content: flex-start;
    gap: 10px;
  }

  .action_form__row--end {
    justify-content: flex-end;
  }
  
  .action_form__input {
    flex: 1;
    display: flex;
    flex-direction: column;
    span {
      padding: 0 5px 5px 5px;
      font-size: 80%;
    }
    input {
      flex-grow: 1;
      background-color: var(--color-gray);
    }
  }
  
  .action_form__textarea {
    flex: 1;
    display: flex;
    flex-direction: column;
    span {
      padding: 0 5px 5px 5px;
      font-size: 80%;
    }
    textarea {
      width: 100%;
      min-height: 150px;
      resize: vertical;
      background-color: var(--color-gray);
    }
  }
}