.user_preview {
  display: flex;
  flex-direction: column;
  .user_preview__header {
    display: flex;
    gap: 10px;
    align-items: center;
  }
  .user_preview__content {
    max-height: 1000px;
    padding-top: 10px;
    overflow: hidden;
    transition: height, max-height, padding-top .2s linear;

    display: flex;
    flex-direction: column;
  }
  .user_preview__row {
    display: flex;
    gap: 10px;
  }
  .user_preview__btns {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
}