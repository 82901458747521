.order-preview {
  cursor: pointer;

  display: flex;
  flex-direction: column;
  .order-preview__header {
    display: flex;
    justify-content: space-between;
    strong {
      font-size: 110%;
    }
  }
  .order-preview__tags {
    margin-top: 10px;

    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: flex-start;
  }
  .order-preview__tag {
    padding: 10px;
    border-radius: 10px;

    display: flex;
    gap: 5px;
    flex-direction: column;
    span {
      font-size: 80%;
    }
    strong {
      font-size: 95%;
    }
  }
}